<template>
  <div class="relative">
    <input :id="$attrs.for" :name="$attrs.for" :required="required" placeholder="******"
           :type="show_password ? 'text': 'password'" autocomplete="false" class="appearance-none w-full input" @change="emit('set', $event)">

    <div class="absolute inset-y-0 right-0 flex items-center pr-3">
      <icon :class="show_password ? 'text-primary-500' : 'text-gray-400'" class="hover:cursor-pointer"
            name="ic:outline-remove-red-eye"
            size="14"
            @click="show_password = !show_password"/>
    </div>
  </div>
</template>

<script setup>
defineProps({
  placeholder: {
    type: String,
    required: false,
  },

  required: {
    type: Boolean,
    default: false,
  },
})

const emit = defineEmits(['set'])

const show_password = ref(false)
</script>
